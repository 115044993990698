<template>
  <!-- no nav for now ;)
  <div class='d-none' id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </div> -->
  <router-view/>
</template>

<script>
export default {
  name: "App",
  components: {},
  mounted() {
    document.title = 'Kevin Becker';
    console.log(
      '%c Made with ❤️',
      'padding: 6px; background: black; color: white; font-size: 14pt; font-weight: bold; font-style: italic;',
    );
  }
}
</script>

<style lang="scss">
@import '@/scss/startup.scss';
@import '@/scss/_colors.scss';

body {
  background: $white!important;
  overflow-x: hidden!important;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // color: $light-gray;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
