<template>
  <div>
    <resume />
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import Resume from '../components/Resume.vue'

export default {
  name: 'Home',
  components: {
    Resume,
    // HelloWorld
  },
  mounted() {
    document.title = "Kevin Becker - Resume";
  },
}
</script>

<style lang='scss' scoped>
</style>
