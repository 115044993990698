<template>
  <div
    class='icon-list'
    v-bind='$attrs'
  >
    <template
      v-for='listItem in listItems'
      :key='listItem.itemContent'
    >
      <div>
        <i :class='listItem.iconClasses'></i>
      </div>
      <a
        v-if='listItem.itemLink'
        :href='listItem.itemLink'
        target='_blank'
        class='link--black'
      >
        {{ listItem.itemContent }}
      </a>
      <div
        v-else
      >
        {{ listItem.itemContent }}
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "IconList",
  components: {

  },
  props: {
    listItems: {
      type: Array,
      required: true,
      validator(listItems) {
        const listItemsLength = listItems.length;
        const validKeys = ['iconClasses', 'itemContent'];
        for( let index = 0; index < listItemsLength; ++index ) {
          const thisListItem = listItems[index];
          if ( ! Object.keys(thisListItem).every( e => validKeys.includes(e)) ) {
            return false;
          }
        }
        return true;
      },
    }
  }
}
</script>

<style lang='scss' scoped>
  .icon-list {
    display: grid;
    grid-auto-flow: row;
    gap: 0.15rem 0.5rem;
    grid-template-columns: 1.25em max-content;
  }
</style>
