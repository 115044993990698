<template>
  <h1
    v-bind='$attrs'
  >
    <slot></slot>
  </h1>
</template>

<script>
export default {
  name: "Heading1",
  components: {

  },
}
</script>

<style lang='scss' scoped>
</style>