<template>
  <div 
    id='resume__parent'
    class='d-grid font--atk-hyperleg bg--light-grey bg-gradient'
  >
    <div
      id='resume__sidebar'
    >
      <div
        class='bg--yellow bg-gradient p-4 py-5 px-sm-5'
      >
        <div
          id='resume__contact'
          class='ml-lg-auto'
        >
          <heading-1
            class='fw-bold text-uppercase mb-0'
          >
            Kevin Becker
          </heading-1>
          <heading-2
            class='fst-italic mb-5'
          >
            Software Engineer
          </heading-2>
          <icon-list
            :listItems="contactListItems"
          />
        </div>
      </div>
      <div
        class='p-4 py-5 px-sm-5'
      >
        <div
          id='resume__generalDetails'
        >
          <div
            id='resume__edu'
            class='mb-4'
          >
            <heading-2
              class='fw-bold text-uppercase'
            >
              Education
            </heading-2>
            <p class='lead mb-0'>B.S.</p>
            <p class='lead mb-0' style='line-height: 1'>Computer Science</p>
            <heading-4
              class='text--yellow fw-bold'
            >
              Rochester Institute of Technology
            </heading-4>
            <icon-list
              :listItems="educationListItems"
            />
          </div>
          <div id='resume__skills'>
            <heading-2
              class='fw-bold text-uppercase'
            >
              Skills
            </heading-2>
            <template
              v-for='(skillSet, _, skillSetIndex) in skillSets'
              :key='skillSet._id'
            >
              <div
                :id='skillSet._id'
              >
                <heading-5
                  class='text--yellow fw-bold mb-3'
                >
                  {{ skillSet.name }}
                </heading-5>
                <template
                  v-for='(skillEntry, index) in skillSet.entries'
                  :key='skillEntry'
                >
                  <p
                    class='lead'
                    :class='{
                      "mb-1": index != (skillSet.entries.length-1),
                      "mb-0": (index == (skillSet.entries.length-1) && skillSetIndex == (Object.keys(skillSets).length - 1)),
                    }'
                  >
                    {{ skillEntry }}
                  </p>
                </template>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div
      id='resume__main'
      class='bg--white p-4 py-5 px-sm-5'
    >
      <div
        id='resume__careerObjectives'
        class='mb-5'
      >
        <heading-2
          class='fw-bold text-uppercase'
        >
          Career Objective
        </heading-2>
        <p class='lead'>
          Seeking full time employment which leverages my interpersonal skills to achieve the goals of a company that focuses on customer satisfaction and customer experience. To secure a challenging position in a reputable organization to expand my learnings, knowledge, and skills, as well as leverage previous work as a full-stack web developer. A team player aiming to deliver beautiful, fast, and original experiences.
        </p>
      </div>
      <div
        id='resume__workExperience'
        class='mb-5'
      >
        <heading-2
          class='fw-bold text-uppercase'
        >
          Work Experience
        </heading-2>
        <template
          v-for='workExperience in workExperiences'
          :key='workExperience.title'
        >
          <heading-2>
            {{ workExperience.title }}
          </heading-2>
          <heading-4 class='fw-bold text--yellow'>
            {{ workExperience.companyName }}
          </heading-4>
          <div class='d-flex my-2 flex-column flex-lg-row' style='gap: 0.25rem 3rem;'>
            <div class='d-flex align-items-center'>
              <i class='fas fa-calendar-alt text--gray me-2'></i>
              <p class='lead mb-0'>
                {{ workExperience.employmentDuration }}
              </p>
            </div>
            <div
              v-if='workExperience.location'
              class='d-flex align-items-center'
            >
              <i class='fas fa-map-marker-alt text--gray me-2'></i>
              <p class='lead mb-0'>
                {{ workExperience.location }}
              </p>
            </div>
          </div>
          <ul class='mb-5'>
            <li
              v-for='point in workExperience.points'
              :key='point'
              class='lead'
            >
              {{ point }}
            </li>
          </ul>
        </template>
      </div>

      <div
        id='resume__projects'
      >
        <heading-2
          class='fw-bold text-uppercase'
        >
          Projects
        </heading-2>
        <template
          v-for='projectExperience in projectExperiences'
          :key='projectExperience.projectName'
        >
          <heading-2>
            {{ projectExperience.projectName }}
          </heading-2>
          <heading-4 class='fw-bold text--yellow'>
            {{ projectExperience.title }}
          </heading-4>
          <div class='d-flex align-items-center'>
            <i class='fas fa-calendar-alt text--gray me-2'></i>
            <p class='lead mb-0'>
              {{ projectExperience.projectDuration }}
            </p>
          </div>
          <ul>
            <li
              v-for='point in projectExperience.points'
              :key='point'
              class='lead'
            >
              {{ point }}
            </li>
          </ul>
        </template>
      </div>

    </div>
  </div>
</template>

<script>
import Heading1 from './utilities/headers/Heading1.vue';
import Heading2 from './utilities/headers/Heading2.vue';
import Heading4 from './utilities/headers/Heading4.vue';
import Heading5 from './utilities/headers/Heading5.vue';
import IconList from './utilities/lists/IconList.vue';
// import TypeOut from './utilities/TypeOut.vue'

  export default {
  name: 'Resume',
  components: {
    // TypeOut,
    Heading1,
    Heading2,
    Heading4,
    Heading5,
    IconList,
  },
  data() {
    return {
      contactListItems: [
        { iconClasses: 'fas fa-fw fa-envelope', itemContent: 'kevin@kevinbecker.us', itemLink: 'mailto:kevin@kevinbecker.us' },
        { iconClasses: 'fas fa-fw fa-phone', itemContent: '(518) 495-3302' },
        { iconClasses: 'fas fa-fw fa-map-marker-alt', itemContent: 'Schenectady, NY' },
        { iconClasses: 'fas fa-fw fa-globe', itemContent: 'kevinbecker.us', itemLink: 'https://kevinbecker.us' },
        { iconClasses: 'fab fa-fw fa-linkedin', itemContent: 'linkedin.com/in/kevin-becker' , itemLink: 'https://linkedin.com/in/kevin-becker' },
      ],
      educationListItems: [
        { iconClasses: 'fas fa-fw fa-calendar-alt text--grey', itemContent: '2017 - 2021' },
        { iconClasses: 'fas fa-fw fa-map-marker-alt text--grey', itemContent: 'Rochester, NY' },
        { iconClasses: 'fas fa-fw fa-graduation-cap text--grey', itemContent: 'GPA: 3.99' },
      ],
      skillSets: {
        softwareLanguages: {
          _id: 'resume__skillsSoftware',
          name: "Software Languages",
          entries: [
            'JavaScript (ES2015+)',
            'TypeScript',
            'React / Vue / Angular',
            'PHP',
            'Laravel',
            'Python',
            'C, C++, C#',
            'HTML',
            'CSS / CSS Pre-processors (SCSS)',
          ]
        },
        utilitiesAndPatterns: {
          _id: 'resume__skillsUtilities',
          name: "Utilities & Patterns",
          entries: [
            'Adobe Creative Suite',
            'AWS Suite (Cloud compute, serverless, networking)',
            'Containers (Docker)',
            'Git',
            'NodeJS',
            'Databases (MySQL, SQLite, MongoDB)',
          ]
        },
        spokenLanguages: {
          _id: 'resume__skillsSpokenLanguages',
          name: "Spoken Languages",
          entries: [
            'English (native)',
            'French (business fluent)',
          ]
        },
      },
      workExperiences: [
        {
          title: "Software Developer",
          companyName: "ARE Event Productions",
          employmentDuration: "May 2017 - current",
          location: 'Albany, NY',
          points: [
            'Write real-time data aggregation and scoring engine for timed competitions.',
            'Develop feature-rich full-stack web applications and marketing activations.',
            'Migrate traditionally-hosted applications to scalable cloud infrastructure.'
          ]
        },
        {
          title: "Freelance Web Application Developer",
          companyName: "USA Track & Field",
          employmentDuration: "January 2017 - May 2020",
          points: [
            'Developed and maintained a web application to modernize certification of road race courses.',
            'Worked with a team of 5 to architect, implement, and support a complete toolkit.',
            'Simplified communication of course certification to a single source.',
          ]
        },
        {
          title: "Classroom Technology Support",
          companyName: "Rochester Institute of Technology",
          employmentDuration: "November 2017 - May 2019",
          location: 'Rochester, NY',
          points: [
            'Delivered in-person and over-the-phone tech support to staff and students using technologies on campus.',
            'Performed routine maintenance of technologies in lecture halls, egs. projector lens and lamp replacements and media source checks.',
            'Managed connected classroom technology through Extron and Crestron management tools.'
          ]
        }
      ],
      projectExperiences: [
        {
          projectName: "Empower Athlete",
          title: "Web Application Developer",
          projectDuration: "March 2021 - December 2021",
          points: [
            'Created a series of monthly activations to highlight the unique heritage of employees at Nike.',
            'Implemented mobile-first component-based web applications using Vue.'
          ]
        }
      ]
    }
  },
  methods: {
  },
  mounted() {
  }
}
</script>

<style lang='scss' scoped>
@import '@/scss/_colors.scss';

@media screen and (min-width: 768px) {
  #resume__parent {
    grid-auto-flow: row;
    grid-auto-rows: max-content;
    grid-template-columns: 2fr 3fr;
  }

  #resume__contact,
  #resume__generalDetails {
    max-width: 400px;
    margin-left: auto;
  }
}
</style>